import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useOrdersList() {
  // Use toast
  const toast = useToast();

  const refOrderListTable = ref(null);
  const userOptions = ref([]);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "Order ID", sortable: true },
    { key: "user.name", stickyColumn: true, label: "Name (EN)", sortable: true },
    { key: "product.product_name", label: "Product" },
    { key: "discount.name", label: "Discount" },
    { key: "order_qty", label: "Quantity" },
    { key: "order_amt", label: "Amount" },
    { key: "reserve_date", label: "Reserve Date" },
    { key: "create_user.name", label: "Created By" },
    { key: "actions" },
  ];

  const perPage = ref(10);
  const totalOrders = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value
      ? refOrderListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    };
  });

  const refetchData = () => {
    console.log("refOrderListTable", refOrderListTable);
    refOrderListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchOrders = (ctx, callback) => {
    store
      .dispatch("app-order/fetchOrders", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response);
        const { orders, total } = response.data;
        callback(orders);
        totalOrders.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching orders list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const refetchOptions = () => {
    store
      .dispatch("app/fetchOptionList", { user: true })
      .then((res) => (userOptions.value = res.data.users))
      .catch((err) => console.error("fail fetching product options ", err));
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrderRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveOrderRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveOrderStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  onMounted(() => {
    refetchOptions();
  });

  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderListTable,
    ability,
    userOptions,

    resolveOrderRoleVariant,
    resolveOrderRoleIcon,
    resolveOrderStatusVariant,
    refetchData,
  };
}
