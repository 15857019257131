import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/order/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/order/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addOrder(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/order", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateOrder(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/order", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteOrder(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/order`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOptions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/options?${queryParams}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
  },
};
